import {
    btnsNamesConstants,
    filtersConstants,
    globalConstants,
    inputsLabelsConstants,
    messagesConstants,
    modulesConstants,
    tooltipsConstants,
} from "@constants";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
    Autocomplete,
    Avatar,
    Box,
    Button,
    Checkbox,
    IconButton,
    Paper,
    Table as MuiTable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { ListActionsMenu, Modal, StatusLabel } from "atoms";
import { directionsData, routesUrlsData, sortsData } from "data";
import { useAssignEventData, useGetPhotographersListData } from "hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Link,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import { getPhotographers, showLoadingLoader } from "redux/actions";
import * as types from "redux/types";
import { globalStyles, StyledTable, StyledTablePagination } from "styles";
import { getConvertedListData, getQuery, getSearchParams } from "utils";

function TablePaginationActions({
    count,
    onPageChange,
    page,
    rowsPerPage,
}) {
    const {
        firstPage: firstPageTooltip,
        lastPage: lastPageTooltip,
        nextPage: nextPageTooltip,
        previousPage: previousPageTooltip,
    } = tooltipsConstants;

    const clickFirstPageBtnHandler = (event) => onPageChange(
        event,
        0,
    );

    const clickBackBtnHandler = (event) => onPageChange(
        event,
        page - 1,
    );

    const clickNextBtnHandler = (event) => onPageChange(
        event,
        page + 1,
    );

    const clickLastPageBtnHandler = (event) => onPageChange(
        event,
        Math.max(
            0,
            Math.ceil(count / rowsPerPage) - 1,
        ),
    );

    return (
        <Box
            display="flex"
            flexShrink="0"
        >
            <Tooltip title={firstPageTooltip}>
                <IconButton
                    disabled={page === 0}
                    onClick={clickFirstPageBtnHandler}
                >
                    <FirstPageIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title={previousPageTooltip}>
                <IconButton
                    disabled={page === 0}
                    onClick={clickBackBtnHandler}
                >
                    <KeyboardArrowLeft />
                </IconButton>
            </Tooltip>
            <Tooltip title={nextPageTooltip}>
                <IconButton
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    onClick={clickNextBtnHandler}
                >
                    <KeyboardArrowRight />
                </IconButton>
            </Tooltip>
            <Tooltip title={lastPageTooltip}>
                <IconButton
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    onClick={clickLastPageBtnHandler}
                >
                    <LastPageIcon />
                </IconButton>
            </Tooltip>
        </Box>
    );
}

function Table({
    columnsNames,
    data,
    directionArray,
    fields,
    getListAction,
    moduleName,
    moduleRouteUrl,
    paginated = true,
    sortArray,
}) {
    const dispatch = useDispatch();

    const photographersStoredData = useSelector((state) => state.photographersReducer.data);

    const [page, setPage] = useState(0); // eslint-disable-line

    const [limit, setLimit] = useState(10);

    const [modalData, setModalData] = useState({
        btnColor: null,
        btnName: null,
        content: null,
        id: null,
        show: false,
        submitModalActionHandler: () => {},
        title: null,
    });

    const navigate = useNavigate();

    const { id: idParam } = useParams();

    const [searchParams] = useSearchParams();

    const { linkColor } = globalStyles();

    const {
        data: photographersListData,
        error: photographersListError,
        isError: photographersListIsError,
        isLoading: photographersListIsLoading,
        isSuccess: photographersListIsSuccess,
        mutate: getPhotographersListMutate,
    } = useGetPhotographersListData();

    const {
        isLoading: assignEventIsLoading,
        mutate: assignEventMutate,
    } = useAssignEventData();

    const {
        assignMsgs: { alert: assignAlertMsg },
        fail: failMsg,
        success: successMsg,
    } = messagesConstants;

    const { assign: assignBtnName } = btnsNamesConstants;

    const {
        eventGuests: eventGuestsModule,
        events: eventsModule,
    } = modulesConstants;

    const {
        archived: archivedFilter,
        phone: phoneFilter,
    } = filtersConstants;

    const { assignPhotographers: assignPhotographersInputLabel } = inputsLabelsConstants;

    const { maxRowsPerPage: maxRowsPerPageConstant } = globalConstants;

    const {
        events: {
            guests: eventGuestsRouteUrl,
            photos: eventPhotosRouteUrl,
            slideshow: eventSlideshowRouteUrl,
            templates: { create: createEventTemplatesRouteUrl },
        },
        update: updateRouteUrl,
    } = routesUrlsData;

    const { photographers: photographersDirection } = directionsData;

    const { photographers: photographersSort } = sortsData;

    const {
        data: list,
        pagination: paginationData,
    } = data;

    const {
        count: paginationCount,
        limit: paginationLimit,
        page: paginationPage,
    } = paginationData || {};

    const { data: photographersListStoredData } = photographersStoredData;

    const {
        filter: filterSearchParam,
        search: searchSearchParam,
    } = getSearchParams(searchParams);

    const showAssignModalHandler = (assignedPhotographers, id) => {
        let selectedPhotographers = assignedPhotographers;

        setModalData({
            btnColor: "primary",
            btnName: assignBtnName,
            content: (
                <Autocomplete
                    defaultValue={assignedPhotographers}
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={photographersListStoredData}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            disabled={photographersListIsLoading || photographersListIsError}
                            label={assignPhotographersInputLabel}
                            placeholder={assignPhotographersInputLabel}
                            variant="outlined"
                        />
                    )}
                    renderOption={(props, option, { selected }) => (
                        <Box
                            sx={{ display: "block" }}
                            {...props}
                        >
                            <Checkbox
                                checked={selected}
                                checkedIcon={<CheckBoxIcon color="primary" />}
                                icon={<CheckBoxOutlineBlankIcon />}
                                style={{ marginRight: 8 }}
                            />
                            {`${option.firstName} ${option.lastName}`}
                        </Box>
                    )}
                    disableCloseOnSelect
                    multiple
                    onChange={(_, value) => selectedPhotographers = value}
                />
            ),
            id,
            show: true,
            submitModalActionHandler: () => {
                setModalData({
                    ...modalData,
                    show: false,
                });

                const removedAssignedPhotographers = assignedPhotographers.filter((photographer) => !selectedPhotographers.includes(photographer));

                const addedAssignedPhotographers = selectedPhotographers.filter((photographer) => !assignedPhotographers.includes(photographer));

                assignEventMutate({
                    data: {
                        assign: addedAssignedPhotographers,
                        count: 5,
                        id,
                        page: 0,
                        unAssign: removedAssignedPhotographers,
                    },
                    id,
                });
            },
            title: assignAlertMsg,
        });
    };

    const getListData = (currentPage, currentLimit) => {
        const query = getQuery(
            currentPage,
            currentLimit,
            filterSearchParam,
            searchSearchParam,
        );

        navigate({ search: query });

        if (moduleName === eventGuestsModule) {
            getListAction({
                data: {
                    count: currentLimit,
                    direction: directionArray,
                    page: currentPage,
                    sort: sortArray,
                },
                id: idParam,
                subApi: filterSearchParam || phoneFilter,
            });
        } else {
            getListAction({
                count: currentLimit,
                direction: directionArray,
                page: currentPage,
                sort: sortArray,
                ...searchSearchParam && { search: searchSearchParam },
                isArchived: filterSearchParam === archivedFilter,
            });
        }
    };

    const renderTableCell = (field, row) => {
        if (field === "status") {
            return (
                <StatusLabel
                    label={row[field].label}
                    type={row[field].status}
                    variant="caption"
                />
            );
        }

        if (field === "isDefault") {
            return (
                <Checkbox
                    {...field}
                    checked={row[field]}
                    checkedIcon={<CheckBoxIcon color="primary" />}
                    icon={<CheckBoxOutlineBlankIcon />}
                    style={{ marginRight: 8 }}
                    disabled
                />
            );
        }

        if (field === "photographers") {
            return row[field].length > 0 ? row[field].map(({
                firstName,
                id,
                lastName,
            }) => (
                <Tooltip
                    key={id}
                    title={`${firstName} ${lastName}`}
                >
                    <Button
                        sx={{
                            margin: 0,
                            minWidth: 50,
                            padding: 0,
                        }}
                        onClick={() => showAssignModalHandler(
                            row[field],
                            row.id,
                        )}
                    >
                        <Avatar>{`${firstName.charAt(0)}${lastName.charAt(0)}`}</Avatar>
                    </Button>
                </Tooltip>
            )) : (
                <Tooltip title={assignPhotographersInputLabel}>
                    <IconButton
                        color="primary"
                        size="large"
                        onClick={() => showAssignModalHandler(
                            [],
                            row.id,
                        )}
                    >
                        <ControlPointIcon />
                    </IconButton>
                </Tooltip>
            );
        }

        return moduleName === eventsModule && field === "title" ? (
            <Link
                className={[linkColor, "whiteGrey"].join(" ")}
                to={`${moduleRouteUrl}/${row.id}${updateRouteUrl}`}
            >
                {row[field]}
            </Link>
        ) : <Typography variant="caption">{row[field]}</Typography>;
    };

    useEffect(() => {
        if (moduleName === eventsModule) {
            getPhotographersListMutate({
                direction: photographersDirection,
                sort: photographersSort,
            });
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        setPage(paginationPage - 1);

        setLimit(paginationLimit);
    }, [paginationPage, paginationLimit]); // eslint-disable-line

    useEffect(() => {
        if (assignEventIsLoading) {
            dispatch(showLoadingLoader());

            dispatch({ type: types.EVENTS_REQUEST });
        }
    }, [assignEventIsLoading]); // eslint-disable-line

    useEffect(() => {
        if (photographersListIsSuccess) {
            dispatch(getPhotographers(
                getConvertedListData(photographersListData.data),
                successMsg,
            ));
        }
    }, [photographersListIsSuccess]); // eslint-disable-line

    useEffect(() => {
        if (photographersListIsError) {
            dispatch(getPhotographers(
                null,
                failMsg,
                photographersListError,
            ));
        }
    }, [photographersListIsError]); // eslint-disable-line

    return (
        <StyledTable>
            <TableContainer component={Paper}>
                <MuiTable>
                    <TableHead>
                        <TableRow>
                            {columnsNames.map((col) => <TableCell key={col}>{col}</TableCell>)}
                            <TableCell align="right" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((row) => (
                            <TableRow key={row.id}>
                                {fields.map((field) => (
                                    <TableCell key={field}>
                                        {renderTableCell(
                                            field,
                                            row,
                                        )}
                                    </TableCell>
                                ))}
                                <TableCell align="right">
                                    <ListActionsMenu
                                        activateStatus={row?.status?.status}
                                        archiveStatus={row?.isArchived}
                                        id={row.id}
                                        modalData={modalData}
                                        moduleName={moduleName}
                                        setModalData={setModalData}
                                        routes={{
                                            createTemplate: `${moduleRouteUrl}${createEventTemplatesRouteUrl}?eventId=${row.id}`,
                                            eventGuestPhotos: `${moduleRouteUrl}/${idParam}${eventGuestsRouteUrl}/${row.id}${eventPhotosRouteUrl}`,
                                            eventGuests: `${moduleRouteUrl}/${row.id}${eventGuestsRouteUrl}`,
                                            eventPhotos: `${moduleRouteUrl}/${row.id}${eventPhotosRouteUrl}`,
                                            eventSlideshow: `${moduleRouteUrl}/${row.id}${eventSlideshowRouteUrl}`,
                                            update: `${moduleRouteUrl}/${row.id}${updateRouteUrl}`,
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </MuiTable>
                {paginated && (
                    <StyledTablePagination>
                        <TablePagination
                            ActionsComponent={TablePaginationActions}
                            component="div"
                            count={parseInt(paginationCount)}
                            dir="ltr"
                            labelRowsPerPage={maxRowsPerPageConstant}
                            page={paginationPage}
                            rowsPerPage={paginationLimit}
                            rowsPerPageOptions={[5, 10, 15, 50]}
                            onPageChange={(_, currentPage) => getListData(
                                currentPage,
                                limit,
                            )}
                            onRowsPerPageChange={(event) => getListData(
                                0,
                                parseInt(event.target.value),
                            )}
                        />
                    </StyledTablePagination>
                )}
            </TableContainer>
            <Modal
                btnColor={modalData.btnColor}
                btnName={modalData.btnName}
                content={modalData.content}
                showModal={modalData.show}
                title={modalData.title}
                onSubmitModalAction={modalData.submitModalActionHandler}
                onCloseModal={() => {
                    setModalData({
                        ...modalData,
                        show: false,
                    });
                }}
            />
        </StyledTable>
    );
}

export default Table;
